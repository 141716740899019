export const smallTitle = {
  HOME: 'ContractX ERP',
  CLIENT_ACCOUNTS: 'Client Accounts',
  CONTRACT_MANAGEMENT: 'Contract Management',
  SUBCON_ACCOUNTS: 'Sub-Contractor Accounts',
  SUB_CONTRACT_TENDERS: 'Sub-Contract Tenders',
  SUB_CONTRACT_MANAGEMENT: 'Sub-Contract Management',
  SUPPLIER_ACCOUNTS: 'Supplier Accounts',
  PROJECT_PURCHASES: 'Project Purchases',
  PROJECT_TENDERS: 'Project Tenders',
  PROJECT_COSTING: 'Project Costing',
  PROJECT_COST_ADJUSTMENT: 'Project Cost Adjustment',
  PLANT_MACHINERY: 'Plant & Machinery',
  FUEL_CONSUMPTION: 'Fuel Consumption Management',
  PROFIT_RECOGNITION: 'Profit Recognition',
  FINANCIAL_INTEGRATION: 'Financial Integration',
  SITE_MANAGEMENT: 'Site Management',
  DEFECT_MANAGEMENT: 'Defect Management',
  SITE_ATTENDANCE: 'Site Attendance',
  SITE_WAGES: 'Site Attendance & Wages',
  SITE_DIARY: 'Site Diary',
  SITE_SAFETY: 'Site Safety',
  SITE_INVENTORY: 'Site Inventory',
  SITE_SURVEILLANCE: 'Site Surveillance',
  BUSINESS_INSIGHT: 'Business Insight',
  DIGITAL_DOCS: 'Digital Docs',
  SYSTEM_ADMIN: 'System Admin',
  GROUP_BI: 'Group Business Insights',
  ACCESS_SECURITY: 'Access Security',
  COMPANY_SETUP: 'Company Setup',
  COMMON_SETTINGS: 'Common Settings',
  SUPPLIER_APP: 'Supplier App',
  SUBCON_APP: 'Subcontractor App',
  CLIENT_APP: 'Client App',
  MY_PROFILE: 'My Profile',
  SUPPLIER_PROFILE: 'Supplier Profile',
  SUBCON_PROFILE: 'Sub-Contractor Profile',
  CLIENT_PROFILE: 'Client Profile',
  GENERAL_SETTINGS: 'General Settings',
}

export const contractSubXPackages = {
  WORK_DESK: 'WORK_DESK',
  BILL_OF_QUANTITIES: 'BILL_OF_QUANTITIES',
  WORK_BREAKDOWN_STRUCTURE: 'WORK_BREAKDOWN_STRUCTURE',
  CONTRACT_MANAGEMENT: 'CONTRACT_MANAGEMENT',
  CLIENT_ACCOUNT: 'CLIENT_ACCOUNT',
  DEFECT_MANAGEMENT: 'DEFECT_MANAGEMENT',
  PLANT_MACHINERY: 'PLANT_MACHINERY',
  PROJECT_PURCHASE: 'PROJECT_PURCHASE',
  PROJECT_COSTING: 'PROJECT_COSTING',
  SUBCON_TENDER: 'SUBCON_TENDER',
  SUBCONTRACT_MANAGEMENT: 'SUBCONTRACT_MANAGEMENT',
  SUBCONTRACTOR_ACCOUNT: 'SUBCONTRACTOR_ACCOUNT',
  SUPPLIER_ACCOUNT: 'SUPPLIER_ACCOUNT',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  SITE_ATTENDANCE: 'SITE_ATTENDANCE',
  SITE_INVENTORY: 'SITE_INVENTORY',
  SITE_SURVEILLANCE: 'SITE_SURVEILLANCE',
  SITE_WAGES: 'SITE_WAGES',
  BUSINESS_INSIGHT: 'BUSINESS_INSIGHT',
}

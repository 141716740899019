import React, { lazy } from 'react';

const CostCategoryTreeListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCategoryModule/CostCategoryTreeListing'
  ).then(module => ({
    default: module.CostCategoryTreeListing,
  })),
);

const CostCategoryListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCategoryModule/CostCategoryListing'
  ).then(module => ({
    default: module.CostCategoryListing,
  })),
);

const CostCategoryForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCategoryModule/CostCategoryForm'
  ).then(module => ({
    default: module.CostCategoryForm,
  })),
);

const CostCategoryValidation = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCategoryModule/CostCategoryValidation'
  ).then(module => ({
    default: module.CostCategoryValidation,
  })),
);

const CostCategoryAssignment = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCategoryModule/CostCategoryAssignment'
  ).then(module => ({
    default: module.CostCategoryAssignment,
  })),
);

const costCategoryRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/cost-category' },
    component: <CostCategoryTreeListing />,
  },
  {
    props: { exact: true, path: '/:mode/general-settings/:costCategory' },
    component: <CostCategoryListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/building-material/add',
    },
    component: <CostCategoryForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/:mode/general-settings/building-material/:costCategoryID/edit',
    },
    component: <CostCategoryForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/cost-category/validation/:batchID',
    },
    component: <CostCategoryValidation />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/cost-category/:costCategoryID/assign',
    },
    component: <CostCategoryAssignment />,
  },
];

export default costCategoryRoutes;

import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import { history } from 'containers/App/App';
import jwtDecode from 'jwt-decode';
import { getAccessToken, setAccessToken } from './AccessToken';

const cache = new InMemoryCache({});
const { NODE_ENV, REACT_APP_LOCAL_DB } = process.env;

let contractNodeUrl;
console.log('PROCESS REACT_APP_LOCAL_DB:', REACT_APP_LOCAL_DB);
console.log('NODE_ENV:', NODE_ENV);

switch (REACT_APP_LOCAL_DB) {
  case 'local':
    contractNodeUrl = 'http://localhost:4000/contract-graphql';
    break;
  case 'report':
    contractNodeUrl = 'http://localhost:4000/contract-graphql';
    break;
  case 'server':
    contractNodeUrl = 'https://contractx-dev-api.ifca.io/contract-graphql';
    break;
  case 'azure':
    contractNodeUrl = 'https://contractx-dev-api.ifca.io/contract-graphql';
    break;
  case 'staging':
    contractNodeUrl = 'https://contractx-api.ifca.io/contract-graphql';
    break;
  case 'uat':
    contractNodeUrl = 'https://home-uat-api.contractx.asia/contract-graphql';
    break;
  case 'demo':
    contractNodeUrl = 'https://home-demo-api.contractx.asia/contract-graphql';
    break;
  default:
    contractNodeUrl = 'https://home-api.contractx.asia/contract-graphql';
    break;
}

// NODE_ENV === 'development' ? 'http://localhost:4000/refresh_token_contract' : ''

export const contractNodeRefreshUrl =
  REACT_APP_LOCAL_DB === 'report'
    ? 'http://localhost:4000/refresh_token_contract'
    : REACT_APP_LOCAL_DB === 'local'
    ? 'http://localhost:4000/refresh_token_contract'
    : REACT_APP_LOCAL_DB === 'server'
    ? 'https://contractx-dev-api.ifca.io/refresh_token_contract'
    : REACT_APP_LOCAL_DB === 'azure'
    ? 'https://contractx-dev-api.ifca.io/refresh_token_contract'
    : REACT_APP_LOCAL_DB === 'staging'
    ? 'https://contractx-api.ifca.io/refresh_token_contract'
    : REACT_APP_LOCAL_DB === 'uat'
    ? 'https://home-uat-api.contractx.asia/refresh_token_contract'
    : REACT_APP_LOCAL_DB === 'demo'
    ? 'https://home-demo-api.contractx.asia/refresh_token_contract'
    : 'https://home-api.contractx.asia/refresh_token_contract';

// NODE_ENV === 'development' ? 'http://localhost:61094' : ''

export const contractReportUrl =
  REACT_APP_LOCAL_DB === 'report'
    ? 'http://localhost:61094'
    : REACT_APP_LOCAL_DB === 'local'
    ? 'https://contractxdevreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'server'
    ? 'https://contractxdevreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'azure'
    ? 'https://contractxdevreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'staging'
    ? 'https://contractxuatreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'uat'
    ? 'https://contractxuatreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'demo'
    ? 'https://contractxdemoreporting.contractx.asia'
    : 'https://contractxreporting.contractx.asia';

// Getting access Token and passing it in request headers
const requestLink = new ApolloLink((operation, forward) => {
  return new Observable(observer => {
    let handle: any;
    Promise.resolve(operation)
      .then(operation => {
        const accessToken = getAccessToken();
        if (accessToken) {
          operation.setContext({
            headers: { authorization: `bearer ${accessToken}` },
          });
        } //accessToken is defined
      }) //then operation ends here
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        }); //handle ends here
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  });
});

const uploadLink = createUploadLink({
  uri: contractNodeUrl,
  credentials: 'include',
});

export const Httplink = new HttpLink({
  uri: contractNodeUrl,
  credentials: 'include',
}); //new HttpLink ends

export const ContractClient = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();

        if (!token) return true;

        try {
          const { exp }: any = jwtDecode(token);
          if (Date.now() >= exp * 1000) return false;
          else return true;
        } catch (err) {
          return false;
        }
      },
      fetchAccessToken: () => {
        return fetch(contractNodeRefreshUrl, {
          method: 'POST',
          credentials: 'include',
        });
      },
      handleFetch: accessToken => {
        sessionStorage.setItem('accessToken', accessToken);
        setAccessToken(accessToken);
      },
      handleResponse: (operation, accessTokenField) => async response => {
        const token = await response?.json();
        if (!token?.ok) return { accessToken: '' };
        return { accessToken: token?.accessToken };
      },
      handleError: err => {
        sessionStorage.removeItem('accessToken');
        setAccessToken(''); // expires or invalid token
        history.push('/');
      },
    }),
    onError(err => {
      if (
        err.graphQLErrors?.some(
          er =>
            er.message.toLowerCase().includes('jwt') ||
            er.message.toLowerCase().includes('authenticated'),
        )
      ) {
        history.push('/login', {
          state: err.graphQLErrors,
        });
      }
    }),
    requestLink,
    uploadLink,
    Httplink,
  ]),
  cache,
});

// export const Uploadclient = new ApolloClient({
//   cache,
//   link: ApolloLink.from([requestLink, uploadLink]),
// });

import React, { lazy } from 'react';

const CompanyPolicyListing = lazy(() =>
  import('../CompanyPolicy/CompanyPolicyListing').then(module => ({
    default: module.CompanyPolicyListing,
  })),
);
const SignaturePolicyListing = lazy(() =>
  import('../CompanyPolicy/SignaturePolicyListing').then(module => ({
    default: module.SignaturePolicyListing,
  })),
);
const TermsAndConditionsForm = lazy(() =>
  import('../CompanyPolicy/TermsAndConditionsForm').then(module => ({
    default: module.TermsAndConditionsForm,
  })),
);
const SignaturePolicyForm = lazy(() =>
  import('../CompanyPolicy/SignaturePolicyForm').then(module => ({
    default: module.SignaturePolicyForm,
  })),
);

const companyPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/company-policy',
    },
    component: <CompanyPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/company-policy/signature-policy',
    },
    component: <SignaturePolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/company-setup/submenu/:CompanyID/company-policy/signature-policy/:type',
    },
    component: <SignaturePolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/company-policy/terms-conditions',
    },
    component: <TermsAndConditionsForm />,
  },
];

export default companyPolicyRoutes;

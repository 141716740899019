import React, { lazy } from 'react';
import prjCostingParameterRoutes from './DigitalReporting/PrjCostingParameterRoutes';
import prjCostingReportingRoutes from './DigitalReporting/PrjCostingReportingRoutes';
import miscExpenseRoutes from './MiscExpense/MiscExpenseRoutes';
import projectExpenseRoutes from './ProjectExpense/ProjectExpenseRoutes';

const ProjectCostingContractListing = lazy(() =>
  import('./ProjectCostingContractListing').then(module => ({
    default: module.ProjectCostingContractListing,
  })),
);

const ProjectCostingSubmenu = lazy(() =>
  import('./ProjectCostingSubmenu').then(module => ({
    default: module.ProjectCostingSubmenu,
  })),
);

const BudgettingSubmenu = lazy(() =>
  import('./Budgetting/BudgettingSubmenu').then(module => ({
    default: module.BudgettingSubmenu,
  })),
);

const BudgettingRevisionDetails = lazy(() =>
  import('./Budgetting/BudgettingRevisionDetails').then(module => ({
    default: module.BudgettingRevisionDetails,
  })),
);

const CostingDetail = lazy(() =>
  import('./Costing/CostingDetail').then(module => ({
    default: module.CostingDetail,
  })),
);

const CostingCategoryDetail = lazy(() =>
  import('./Costing/CostingCategoryDetail').then(module => ({
    default: module.CostingCategoryDetail,
  })),
);

const ProfitLossDetail = lazy(() =>
  import('./ProfitLoss/ProfitLossDetail').then(module => ({
    default: module.ProfitLossDetail,
  })),
);

const ProfitLossVODetail = lazy(() =>
  import('./ProfitLoss/ProfitLossVODetail').then(module => ({
    default: module.ProfitLossVODetail,
  })),
);

const CashFlowDetail = lazy(() =>
  import('./CashFlow/CashFlowDetail').then(module => ({
    default: module.CashFlowDetail,
  })),
);

const CashFlowGraphDetail = lazy(() =>
  import('./CashFlow/CashFlowGraphDetail').then(module => ({
    default: module.CashFlowGraphDetail,
  })),
);

const CostPerformanceDetail = lazy(() =>
  import('./CostPerformance/CostPerformanceDetail').then(module => ({
    default: module.CostPerformanceDetail,
  })),
);

const FinancialStatusDetail = lazy(() =>
  import('./FinancialStatus/FinancialStatusDetail').then(module => ({
    default: module.FinancialStatusDetail,
  })),
);

const BudgetAnalysisDetail = lazy(() =>
  import('./BudgetAnalysis/BudgetAnalysisDetail').then(module => ({
    default: module.BudgetAnalysisDetail,
  })),
);

const BudgetVsActualAnalysis = lazy(() =>
  import('./BudgetAnalysis/BudgetVsActualAnalysis').then(module => ({
    default: module.BudgetVsActualAnalysis,
  })),
);

const POPDFPreviewDetail = lazy(() =>
  import(
    '../ProjectPurchaseModule/LedgerMobileViewModule/POPreviewDetailPDF'
  ).then(module => ({
    default: module.POPreviewPDF,
  })),
);

//PDF
const DNtoSubconPreview = lazy(() =>
  import(
    '../SubconAccountModule/SubconAccountPDF/SubconPreviewDetailPDF/DNtoSubconPreviewDetailPDF'
  ).then(module => ({
    default: module.DNtoSubconPreviewDetailPDF,
  })),
);

const DNtoSupplierPreview = lazy(() =>
  import(
    '../SupplierAccountModule/SupplierAccountPDF/DNtoSupplierPreviewDetailPDF'
  ).then(module => ({
    default: module.DNtoSupplierPreviewDetailPDF,
  })),
);

const SubcontractMiscInvPreview = lazy(() =>
  import(
    '../SubconAccountModule/SubconAccountPDF/SubconPreviewDetailPDF/SubconMiscInvPreviewDetailPDF'
  ).then(module => ({
    default: module.SubconMiscInvPreviewDetailPDF,
  })),
);

const SubconProgressClaimPreview = lazy(() =>
  import(
    '../SubconAccountModule/SubconAccountPDF/SubconPreviewDetailPDF/SubconProgressClaimPreviewDetailPDF'
  ).then(module => ({
    default: module.SubconProgressClaimPreviewDetailPDF,
  })),
);

const SubconRetentionClaimPreview = lazy(() =>
  import(
    '../SubconAccountModule/SubconAccountPDF/SubconPreviewDetailPDF/SubconRetentionClaimPreviewDetailPDF'
  ).then(module => ({
    default: module.SubconRetentionClaimPreviewDetailPDF,
  })),
);

const DebitNotesPreview = lazy(() =>
  import(
    '../ClientContractAccountModule/ClientContractPDF/DebitNotesPreviewDetailPDF'
  ).then(module => ({
    default: module.DebitNotesPreviewDetailPDF,
  })),
);

const MiscellaneousPreview = lazy(() =>
  import(
    '../ClientContractAccountModule/ClientContractPDF/MiscellaneousInvoicesPreviewDetailPDF'
  ).then(module => ({
    default: module.MiscellaneousPreviewDetailPDF,
  })),
);

const CNtoSupplierPreview = lazy(() =>
  import(
    '../SupplierAccountModule/SupplierAccountPDF/CNtoSupplierPreviewDetailPDF'
  ).then(module => ({
    default: module.CNtoSupplierPreviewDetailPDF,
  })),
);

const CNtoSubconPreview = lazy(() =>
  import(
    '../SubconAccountModule/SubconAccountPDF/SubconPreviewDetailPDF/CNtoSubconPreviewDetailPDF'
  ).then(module => ({
    default: module.CNtoSubconPreviewDetailPDF,
  })),
);
const CreditNotesPreview = lazy(() =>
  import(
    '../ClientContractAccountModule/ClientContractPDF/CreditNotesPreviewDetailPDF'
  ).then(module => ({
    default: module.CreditNotesPreviewDetailPDF,
  })),
);

const ProgressClaimsPreview = lazy(() =>
  import(
    '../ClientContractAccountModule/ClientContractPDF/ProgressClaimsPreviewDetailPDF'
  ).then(module => ({ default: module.ProgressClaimsPreviewDetailPDF })),
);

const RetentionPreviewDetail = lazy(() =>
  import(
    '../ClientContractAccountModule/ClientContractPDF/RetentionPreviewDetailPDF'
  ).then(module => ({
    default: module.RetentionPreviewDetailPDF,
  })),
);

const SubcontractPaymentPreview = lazy(() =>
  import(
    '../SubconAccountModule/SubconAccountPDF/SubconPreviewDetailPDF/SubconPaymentsPreviewDetailPDF'
  ).then(module => ({ default: module.SubconPaymentsPreviewDetailPDF })),
);

const SubcontractAdvancePreview = lazy(() =>
  import(
    '../SubconAccountModule/SubconAccountPDF/SubconPreviewDetailPDF/SubconAdvancePreviewDetailPDF'
  ).then(module => ({
    default: module.SubconAdvancePreviewDetailPDF,
  })),
);

const SubcontractRefundsPreview = lazy(() =>
  import(
    '../SubconAccountModule/SubconAccountPDF/SubconPreviewDetailPDF/SubconRefundsPreviewDetailPDF'
  ).then(module => ({
    default: module.SubconRefundsPreviewDetailPDF,
  })),
);

const PaymentsPreviewDetail = lazy(() =>
  import(
    '../SupplierAccountModule/SupplierAccountPDF/PaymentsPreviewDetailPDF'
  ).then(module => ({
    default: module.PaymentsPreviewDetailPDF,
  })),
);

const DepositsPreviewDetail = lazy(() =>
  import(
    '../SupplierAccountModule/SupplierAccountPDF/DepositsPreviewDetailPDF'
  ).then(module => ({
    default: module.DepositsPreviewDetailPDF,
  })),
);

const AdvancePreviewDetail = lazy(() =>
  import(
    '../ClientContractAccountModule/ClientContractPDF/AdvancePreviewDetailPDF'
  ).then(module => ({
    default: module.AdvancePreviewDetailPDF,
  })),
);

const ReceiptsPreviewDetail = lazy(() =>
  import(
    '../ClientContractAccountModule/ClientContractPDF/ReceiptsPreviewDetailPDF'
  ).then(module => ({
    default: module.ReceiptsPreviewDetailPDF,
  })),
);

const RefundsPreviewDetail = lazy(() =>
  import(
    '../ClientContractAccountModule/ClientContractPDF/RefundsPreviewDetailPDF'
  ).then(module => ({
    default: module.RefundsPreviewDetailPDF,
  })),
);

const projectCostingRoutes = [
  ...miscExpenseRoutes,
  ...prjCostingParameterRoutes,
  ...prjCostingReportingRoutes,
  ...projectExpenseRoutes,
  {
    props: { exact: true, path: '/project-costing' },
    component: <ProjectCostingContractListing />,
  },
  {
    props: { exact: true, path: '/project-costing/submenu/:contractID' },
    component: <ProjectCostingSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/budget',
    },
    component: <BudgettingSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/budget/class-:costClass/original',
    },
    component: <BudgettingRevisionDetails formMode="Original" />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/budget/class-:costClass/revision',
    },
    component: <BudgettingRevisionDetails formMode="Revision" />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/costing',
    },
    component: <CostingDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/costing/:type',
    },
    component: <CostingCategoryDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/profit-loss',
    },
    component: <ProfitLossDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/profit-loss/:voID/vo-detail',
    },
    component: <ProfitLossVODetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/cash-flow',
    },
    component: <CashFlowDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/cash-flow/:mode/:monthYear',
    },
    component: <CashFlowGraphDetail type={'cash-flow'} />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/cost-performance',
    },
    component: <CostPerformanceDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/financial-status',
    },
    component: <FinancialStatusDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/budget-analysis',
    },
    component: <BudgetAnalysisDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/budget-actual-analysis',
    },
    component: <BudgetVsActualAnalysis />,
  },
  {
    props: {
      exact: true,
      path: `/project-costing/submenu/:id/costing/:type/:pId/preview`,
    },
    component: <POPDFPreviewDetail type="project-costing" />,
  },
  {
    props: {
      exact: true,
      path: `/project-costing/submenu/:id/cash-flow/purchase-order/:pId/preview`,
    },
    component: <POPDFPreviewDetail type="cash-flow" />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/dn-to-subcon/:transactionID/preview',
    },
    component: <DNtoSubconPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/dn-to-supplier/:transactionID/preview',
    },
    component: <DNtoSupplierPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/subcon-misc/:transactionID/preview',
    },
    component: <SubcontractMiscInvPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/subcon-claim/:transactionID/preview',
    },
    component: <SubconProgressClaimPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/subcon-retention/:transactionID/preview',
    },
    component: <SubconProgressClaimPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/contract-debit/:transactionID/preview',
    },
    component: <DebitNotesPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/contract-misc/:transactionID/preview',
    },
    component: <MiscellaneousPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/cn-to-supplier/:transactionID/preview',
    },
    component: (
      <CNtoSupplierPreview transactionType="Credit Notes to Supplier" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/cn-to-subcon/:transactionID/preview',
    },
    component: <CNtoSubconPreview transactionType="Credit Notes to Subcon" />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/contract-credit/:transactionID/preview',
    },
    component: <CreditNotesPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/contract-claim/:ID/preview',
    },
    component: <ProgressClaimsPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/retention-claim/:ID/preview',
    },
    component: <RetentionPreviewDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/subcon-payment/:transactionID/preview',
    },
    component: (
      <SubcontractPaymentPreview transactionType="Subcontract Payment" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/subcon-advance/:transactionID/preview',
    },
    component: <SubcontractAdvancePreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/subcon-refund/:transactionID/preview',
    },
    component: <SubcontractRefundsPreview />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/supplier-payment/:transactionID/preview',
    },
    component: <PaymentsPreviewDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/supplier-advance/:transactionID/preview',
    },
    component: <DepositsPreviewDetail transactionType="Supplier Advances" />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/contract-advance/:transactionID/preview',
    },
    component: <AdvancePreviewDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/contract-receipt/:transactionID/preview',
    },
    component: <ReceiptsPreviewDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/:psType/contract-refund/:transactionID/preview',
    },
    component: <RefundsPreviewDetail />,
  },
];

export default projectCostingRoutes;
